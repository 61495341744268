.geneSelectedRow {
  background-color:rgb(247,245,230);
}
.geneNonSelectedRow {

}

.gene-page-container h3 {
  margin-top: 15px;
}

.list-item-container {
  font-size: 1.2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: fit-content;
  z-index: 9;
  padding-left: 10px;
}

.list-item-container a {
  font-size: 1rem;
  margin-left: 10px;
}

.list-item-box {
  font-size: 1.1rem;
  background-color: white;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3px;
  border-radius: 20px;
  z-index: 9;
  border: 1px solid gray;
}

.vl {
  border-left: 1px solid gray;
  margin-left: 33px;
  position: absolute;
  height: -webkit-fill-available;
  margin-top: 10px;
  margin-bottom: 20px;
}

.gene-content-container {
  background-color: rgb(244, 244, 244);
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
}