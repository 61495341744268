@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700");

div:has(> #only-chip-label) {
  margin-left: 0px !important;
  padding-left: 0px !important;
}
.coding div {
  font-family: "Open Sans", sans-serif;
  font-size: 0.8vw;
  padding: 10px;
}

.coding .container {
    margin-left: 10px;
    margin-right: 10px;
    width: 90% !important;
}

.coding .__react_component_tooltip {
  pointer-events: auto !important;
}

.codingTable table {
  width: 90%;
  border: 0;
}

.codingTable > tbody > tr:nth-of-type(even) {
  background: #eeeeee;
}

.coding th {
  text-align: left;
  white-space: nowrap;
}

.coding td {
  white-space: nowrap;
}

.coding tr:hover {
  font-weight: 700;
}

.coding .title {
  font-weight: bold;
  align-self: center;
}

.coding .buttonGroup {
  padding-right: 20px;
}

.coding .button {
  background-color: white;
  cursor: pointer;
  border-color: black;
}

.coding .input {
  border-radius: 0px;
  border-color: black;
}

.coding .loading {
  background-color: #dddddd;
}

.coding .help {
  font-weight: bold;
  font-size: 1.5em;
  cursor: help;
  float: right;
}
